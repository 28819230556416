
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams} from "react-router-dom";
import './Banner.scss';

const Banner = (props) => {

    let navigate = useNavigate(); 
    const routeChange = (path) =>{ 
        //let path = `newPath`; 
        navigate(path);
    }

    const buttonUrl = props.buttonUrl;

    const buttonBackgroundNormal = props.buttonBackgroundNormal;
    const buttonBackgroundHover = props.buttonBackgroundHover;

    const buttonBorderNormal = props.buttonBorderNormal;
    const buttonBorderHover = props.buttonBorderHover;



    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const [buttonBackColor, setButtonBackColor] = useState(buttonBackgroundNormal);
    const [buttonBorderColor, setButtonBorderColor] =useState(buttonBorderNormal);


    const buttonTextColorNormal = props.buttonTextColorNormal;
    const buttonTextColorHover = props.buttonTextColorHover;

    const [buttonTextColor, setButtonTextColor] = useState(buttonTextColorNormal);

    const handleMouseEnter = () => {
        setIsButtonHovered(true);
        setButtonBackColor(buttonBackgroundHover);
        setButtonBorderColor(buttonBorderHover);
        setButtonTextColor(buttonTextColorHover);
    };

    const handleMouseLeave = () => {
        setIsButtonHovered(false);
        setButtonBackColor(buttonBackgroundNormal);
        setButtonBorderColor(buttonBorderNormal);
        setButtonTextColor(buttonTextColorNormal);
    };

    return (
        <div className="banner">
        <img className={`banner__image ${isButtonHovered ? 'banner__image--blurred' : ''}`}
         src={props.bannerImageSource} alt="Banner" />
        <div className="banner__content">
            <h1 className="banner__title"
            style ={{ textShadow: "0 0 5px " + buttonBorderColor }}
            >{props?.bannerTitle}</h1>
            <p className="banner__text"
            style ={{ textShadow: "0 0 5px " + buttonBorderColor }}
            >{props?.bannerText}</p>
            
            <a href={"/gallery/" + buttonUrl}>
            <button className="banner__button"
            style={{ backgroundColor: buttonBackColor, borderColor: buttonBorderColor, color: buttonTextColor }} 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            
            >View Gallery</button>
            </a>
            
        </div>
        </div>
    );
}

export default Banner;




// const GalleryIntro = (props) => {

//     const galleryIntroName = props.galleryIntroName;
//     const galleryIntroText = props.galleryIntroText;
//     const galleryIntroSource = props.galleryIntroSource;

//     return (
//         <div className={"galleryIntroContainer"}>
            
//         </div>
//     );

// }

// export default GalleryIntro;