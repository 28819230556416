import React, { useEffect, useRef } from 'react';
import { parseGIF, decompressFrames } from 'gifuct-js';

const Bitmaper = ({ gifSrc }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const frameCache = new Map();
        
        const onDrawFrame = (frame) => {

            if(frameCache.has(frame.index)) {
                // Use the cached frame
                const cachedCanvas = frameCache.get(frame.index);
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(cachedCanvas, 0, 0);
            } else {
                // Clear the canvas before drawing the new frame
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          
            // Define the scaling factor
            const scaleFactor = 1;
          
            // Resize the canvas to the size of the GIF frame, multiplied by the scaling factor
            canvas.width = frame.dims.width * scaleFactor;
            canvas.height = frame.dims.height * scaleFactor;
          
            // Apply the scaling to the canvas
            ctx.scale(scaleFactor, scaleFactor);
          
            // Get the frame's pixel data
            const pixelData = frame.pixels;
          
            // Define the size of a single bitmap character
            const charWidth = 3;
            const charHeight = 5;
          
            const charSet = '█▓▒░  '.split(''); //YEAH idk needs to be like this

            for (let y = 0; y < frame.dims.height; y += charHeight) {
                for (let x = 0; x < frame.dims.width; x += charWidth) {
                  // Calculate the index of the current pixel
                    const index = x + y * frame.dims.width;
            
                    // Get the color index from the frame's color table
                    const colorIndex = pixelData[index];
                    const color = frame.colorTable[colorIndex];
            
                    // Calculate the pixel's brightness
                    const brightness = (color[0] + color[1] + color[2]) / 3;
                  // Check if the pixel is transparent
                  const isTransparent = color[3] === 0;
            
                  // Choose a character based on the brightness and transparency
                  const charIndex = isTransparent
                    ? charSet.length - 1
                    : Math.floor(brightness / (256 / (charSet.length - 1)));
                  const char = charSet[charIndex];
            
                  // Set the fill style based on the pixel's color
                  //ctx.fillStyle = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] / 255})`;
                    
                  ctx.fillStyle = isTransparent ? 'rgba(0, 0, 0, 0)' : 'rgba(255, 255, 255, 1)';

                  //ctx.fillStyle = isTransparent ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 1)';

                  // Draw the character on the canvas
                  ctx.font = `${charHeight}px monospace`;
                  ctx.fillText(char, x, y + charHeight);
                }
              }
          
            // Reset the scaling after drawing
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            }

            
          };

        fetch(gifSrc)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
            const gifData = new Uint8Array(buffer);
            const parsedGif = parseGIF(gifData);
            const frames = decompressFrames(parsedGif, true);
            console.log(frames);
            // Loop through the frames
            let frameIndex = 0;

            const animate = () => {
            if (frameIndex >= frames.length) frameIndex = 0;
            onDrawFrame(frames[frameIndex]);
            frameIndex++;
            setTimeout(animate, frames[frameIndex]?.delay || 100); // Use frame delay or a default value
            };

            animate();
        });

        return () => {
        // Clean up the canvas when the component unmounts
        ctx && ctx.clearRect(0, 0, canvas.width, canvas.height);
        };
  }, [gifSrc]);

  return <canvas ref={canvasRef} />;
};

export default Bitmaper;