import React, { useEffect, useState } from 'react';
import './Gallery.scss';
import { useNavigate, useParams} from "react-router-dom";
import siteData from '../../site.json';

const Gallery = () => {

    let navigate = useNavigate();

    const { galleryName } = useParams();

    const filterForMap = (galleryName, siteData) => {
        let tempArray = [];
        siteData && siteData.children.map((lowerFolder) => {
            if(lowerFolder?.name === galleryName) {
                lowerFolder.children.map((images) => {
                    if(images.name.includes(".jpg")) {
                        let realSource = `https://peterTheYeeter.b-cdn.net/forArtSite/${galleryName}/${images.name}`;
                        tempArray.push(realSource);
                    }
                })
            }
        })
        return tempArray;
    }

    let imagesToDisplay = filterForMap(galleryName, siteData);

    return (
        <div className="backgroundBoi">
            <div>
                {imagesToDisplay.map((image, index) => {
                    console.log(image);
                    return (<img src={imagesToDisplay[index]} style={{width: "100%" }}/>)
                })}
            </div>
            <button className="floating-back-button" onClick={() => navigate(-1)}>
                {'<'} Back
            </button>
        </div>
    )

}

export default Gallery;