import React, { lazy } from 'react';
import Bitmaper from '../../bitmaper/Bitmaper';
import charChar from '../../assets/animeDance2.gif';
import Banner from '../../Banner/Banner';
import siteData from '../../site.json';
import './Welcome.scss';

const Welcome = () => {

    let dontPrints = ["favorites", "music"];

    const checkIfName = (name) => {
        if(name === "music" || name === "favorites") {
        return false;
        }
        return true;
    }


    return (
        <>
            <div className="welcome-wagon">
                <div className="welcome-wagon-greeting-container">
                <span className="welcome-wagon-greeting-header">Welcome!</span> 
                <br/>
                <span className="welcome-wagon-greeting-subtext">Just a Collection of Stable Diffusion Generations I Liked :)</span>
                <br />
                <span className="welcome-wagon-greeting-subtext">Scroll Down to See Em</span>
                <br/>
                <br/>
                <Bitmaper gifSrc={charChar} />
                {/* <img src={charChar} /> */}
                </div>
            </div>
            {console.log(siteData)}
            <Banner
                bannerTitle = "Favorites"
                bannerText = "My personal favorite generations!" 
                bannerImageSource={`https://peterTheYeeter.b-cdn.net/forArtSite/favorites/00027.jpg`}
                buttonBackgroundNormal = "#8D3122ff"
                buttonBackgroundHover = "#AB3927ff"
                buttonBorderNormal = "#E3B49Bff"
                buttonBorderHover = "#371A13ff"
                buttonUrl={"favorites"}
            />
            {siteData && siteData.children.map((lowerFolder) => {
                console.log(lowerFolder);
                if(lowerFolder?.children[0]?.type !== "folder" && checkIfName(lowerFolder.name)) {
                let folderName = lowerFolder.name;
                console.log(folderName)
                let sauce = lowerFolder.children[0].name;
                console.log(sauce)
                let realSource = `https://peterTheYeeter.b-cdn.net/forArtSite/${folderName}/${sauce}`;
                console.log(realSource);
                return (
                    // <img className="stable-image-fs" src={realSource}loading={lazy}/>
                    <Banner
                    bannerTitle = {lowerFolder?.realName || "NAME NOT FOUND D:"}
                    bannerText = {lowerFolder?.description || "DESCRIPTION NOT FOUND D:"}
                    bannerImageSource={realSource}
                    buttonBackgroundNormal = {lowerFolder?.buttonBackNormal || "#8D3122ff"}
                    buttonBackgroundHover = {lowerFolder?.buttonBackHover || "#AB3927ff"}
                    buttonBorderNormal = {lowerFolder?.buttonBorderNormal || "#E3B49Bff"}
                    buttonBorderHover = {lowerFolder?.buttonBorderHover || "#371A13ff"}
                    buttonTextColorNormal ={lowerFolder?.buttonTextColorNormal || "white"}
                    buttonTextColorHover={lowerFolder?.buttonTextColorHover || "white"}
                    buttonUrl={lowerFolder.name}
                    />
                );
                }
            })}
        </>
    );
}

export default Welcome;