
import logo from './logo.svg';
import siteData from './site.json';
import React, { lazy } from 'react';
import './App.scss';
import Bitmaper from './bitmaper/Bitmaper';
import charChar from './assets/animeDance2.gif';
import Banner from './Banner/Banner';
import Welcome from './Pages/Welcome/Welcome';
import { BrowserRouter as Router, Route, Switch, Link, Routes } from 'react-router-dom';
import Gallery from './Pages/Gallery/Gallery';


function App() {

  return (
    // <div className="App">
    //   <Welcome />
    // </div>
    <Router>
      <Routes>
        <Route exact path="/" element={<Welcome/>} />
        <Route path="gallery/:galleryName" element={<Gallery />} />
      </Routes>
    </Router>
  );
}

export default App;
